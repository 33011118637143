/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "/node_modules/swiper/swiper-bundle";


// My Style
$transition : all 0.4s ease-in-out;
$neutralBG: #F0F7F7;
$primaryColor: #1f75c0;
$secondaryColor: #E25822;
// Breakpoints
@mixin breakpoints($bp: 0){
    @media (min-width: $bp){
        @content;
    }
}




$breakpoints : (
    "xs": 0,
    "sm":576px,
    "md": 767px,
    "lg":992px,
    "xl": 1200px
    
);

@mixin xs{
    @media (min-width: map-get($breakpoints, "xs")){
        @content;
    }
}
@mixin sm{
    @media (min-width: map-get($breakpoints, "sm")){
        @content;
    }
}
@mixin md{
    @media (min-width: map-get($breakpoints, "md")){
        @content;
    }
}
@mixin lg{
    @media (min-width: map-get($breakpoints, "lg")){
        @content;
    }
}
@mixin xl{
    @media (min-width: map-get($breakpoints, "xl")){
        @content;
    }
}

@mixin breakpoint($bp: 0) {
    @media (max-width: $bp){
        @content;
    }
    
}
@mixin medium($bp: 0) {
    @media (min-width: $bp){
        @content;
    }
    
}




.section-paddings{
    padding: 60px 0 60px 0;
}

.container{
    max-width: 100%;
    margin: 0 auto;
    padding-left: 0.7rem;
    padding-right: 0.7rem;
    @include md{
        max-width: 80%;
        padding-left: 0;
        padding-right: 0;
    }
    @include lg{
        max-width: 50rem;
        padding-left: 0;
        padding-right: 0;
    }
    
    @include xl{
        max-width: 80rem;
        padding-left: 0;
        padding-right: 0;
    }
    
}


.hide-for-mobile{
    // hide for tablet and mobile devices
    @include breakpoint(1024px){
        display: none;
    }


}

.hide-for-desktop{
    // hide for desktop viewport width
    @include xl{
        display: none;
    }
}
.hero-section{
    .swiper-button-next,
    .swiper-button-prev{
        border-radius: 50%;
        padding: 1.5rem;
        color: $primaryColor;
        background: #fff;
        box-shadow: 0 11px 29px 0 rgba(0,0,0,.15);
        transition: all 0.4s ease-in-out;
        &::after{
            font-size: 1rem;
        }

        &:hover{
            background: $primaryColor   ;
            color: #fff;
        }
    }

    @include md{
        .swiper-button-next{
            right: 60px;
        }
        .swiper-button-prev{
         left: 60px;
        }

    }

    .swiper-pagination-bullet{
        background: #fff;
        height: 20px;
        width: 20px;

    }

    .swiper-pagination-bullet .active{
        background:transparent;
        border: 2px solid #fff;
        height: 30px;
        width: 30px;
    }
}

// .container{
//     max-width: 70%;
// }

a{
    text-decoration: none;
}

.hr-line{
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px; 
    height: 1px;
    display: block;
    background-color:  rgb(31, 117, 192);

}
h1{
    text-align: center; 
    font-size: 40px;
    line-height: 31px;
}
p{
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    padding: 30px;
    margin-top: 11px;
    color: #303030; 
}

ion-img{
    width: 50%;
}


.flex{
    display: flex;
    
    &-jc-sb{
        justify-content: space-between;
    }

    &-jc-c{
        justify-content: center;
    }
    &-ai-c{
        align-items: center;
    }
}
// ion-header{
//     background-color: #767676;
// }
// ion-title{
//     text-align: center;
//     font-size: 50px;
// }


// green colors 
// $green : 	#008000;
// $palegreen : #98fb98;
// $lightgreen:	#90ee90;
// $darkseagreen:#8fbc8f
// $greenyellow:	#adff2f
// $lime:	#00ff00
// $springgreen:#00ff7f
// $chartreuse:#7fff00
// $limegreen:#32cd32
// $mediumspringgreen:#00fa9a
// $lawngreen:#7cfc00
// $mediumseagreen:	#3cb371
// $seagreen:#2e8b57
// $forestgreen:#228b22
// $darkgreen:	#006400
